<template>
  <span>
    <div class="btn-group" style="vertical-align: top">
      <div
        type="button"
        :class="
          `btn btn-${type} dropdown-toggle ${$attrs.small ? 'btn-xs' : ''}`
        "
        data-toggle="dropdown"
        @click="updatePosition"
      >
        <i class="fa fa-refresh fa-spin" v-if="downloading"></i>
        <i class="fa fa-download" v-else></i>
      </div>
      <ul
        class="dropdown-menu"
        :class="{'fix-right': this.offsetLeft > 250 || $attrs.fixRight}"
      >
        <li v-if="xls">
          <a href="" v-on:click.prevent="onClick('xls')"
            >Microsoft Excel (.xls)
          </a>
          <span
            class="btn-sort btn btn-xs"
            @click.stop.prevent="reverse_xls = !reverse_xls"
            ><i
              class="fa"
              :class="reverse_xls ? 'fa-sort-alpha-asc' : 'fa-sort-alpha-desc'"
            ></i
          ></span>
        </li>
        <li v-if="csv">
          <a href="" v-on:click.prevent="onClick('csv')"
            >Comma-separated Values (.csv)
          </a>
          <span
            class="btn-sort btn btn-xs"
            @click.stop.prevent="reverse_csv = !reverse_csv"
            ><i
              class="fa"
              :class="reverse_csv ? 'fa-sort-alpha-asc' : 'fa-sort-alpha-desc'"
            ></i
          ></span>
        </li>
        <li v-if="cellFormat">
          <div style="border-top: 1px solid #cdcdcd; text-align: center">
            <a
              href="javascript:void()"
              @click.stop.prevent="$emit('toggleCellFormatFormat')"
            >
              <i
                :class="
                  cellFormat.formatted
                    ? 'fa fa-check-square-o'
                    : 'fa fa-square-o'
                "
              ></i>
              {{ $t("format") }}
            </a>
            <a
              href="javascript:void()"
              @click.stop.prevent="$emit('toggleCellFormatUnit')"
              style="margin-left: 40px"
            >
              <i
                :class="
                  cellFormat.unit ? 'fa fa-check-square-o' : 'fa fa-square-o'
                "
              ></i>
              {{ $t("unit") }}
            </a>
          </div>
        </li>
      </ul>
    </div>
  </span>
</template>

<script>
export default {
  name: "DownloadButton",
  props: {
    tableId: String,
    filename: String,
    type: {
      type: String,
      required: false,
      default: "default"
    },
    csv: {
      type: Boolean,
      required: false,
      default: () => true
    },
    xls: {
      type: Boolean,
      required: false,
      default: () => true
    },
    cellFormat: {
      type: Object,
      default: null,
      required: false
    }
  },
  data() {
    return {
      reverse_xls: false,
      reverse_csv: false,
      offsetLeft: 0,
      downloading: false
    };
  },
  computed: {
    fname: function() {
      var self = this;
      var fn = self.filename;
      return (
        fn ||
        new Date()
          .toISOString()
          .replace(/[\-\:T]/g, "")
          .split(".")[0]
          .substr(2)
      );
    }
  },
  methods: {
    onClick(fileType) {
      if (this.$store.getters["dashboard/mode"] == "editor") return;
      if (this.tableId) {
        this.downloading = true;
        fileType == "xls" ? this.downloadAsXLS() : this.downloadAsCSV();
      } else {
        this.downloading = false;
        this.$emit("download", {
          type: fileType,
          inverted: this[`reverse_${fileType}`]
        });
      }
    },
    downloadAsXLS() {
      this.$utils.HTMLTable2XLS(
        document.getElementById(this.tableId),
        this.fname,
        () => {
          this.downloading = false;
          this.$emit("done");
        },
        this.reverse_xls
      );
    },
    downloadAsCSV() {
      this.$utils.HTMLTable2CSV(
        document.getElementById(this.tableId),
        this.fname,
        () => {
          this.downloading = false;
          this.$emit("done");
        },
        this.reverse_csv
      );
    },
    updatePosition() {
      this.offsetLeft = this.$el.offsetLeft;
    }
  },
  mounted() {
    this.updatePosition();
  }
};
</script>

<style scoped>
.fix-right {
  right: 0;
  left: auto;
  margin-top: 0px;
}

.dropdown-menu li {
  white-space: nowrap;
  position: relative;
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 30px 3px 10px;
}

.btn-sort {
  position: absolute;
  top: 3px;
  right: 0px;
  color: #337ab7;
}

.btn-sort:hover {
  cursor: pointer;
  opacity: 0.8;
  color: #f39c12;
}
</style>
